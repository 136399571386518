import builder from '@ahmdigital/urls/lib/builder';

import constants from '../../ahm-constants';

const UrlService = {
  // @ts-ignore - Automatic, Please fix when editing this file
  getProductUrl: (productId) =>
    builder.getProductUrl({
      errorUrl: constants.URLS.SALES.ERROR_404,
      productId,
      rawUrl: constants.URLS.SALES.PRODUCT,
    }),
};

export default UrlService;

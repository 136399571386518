import { createSelector } from 'reselect';
import { get, lowerCase, toLower } from 'lodash/fp';

import { AgeBracket } from '../select-age-bracket';
import constants from '../../ahm-constants';
import getIncomeTierOptions from '../../utils/get-income-tier-options';
import selectAgeBracket from '../select-age-bracket-option';
import selectCustomer from '../select-customer';
import selectCustomerAges, { MemberYouthDiscountAgesType } from '../select-youth-discount-ages';

// @ts-ignore - Automatic, Please fix when editing this file
const getIncomeTier = (customer) => get(customer.incomeTier, getIncomeTierOptions({ incomeType: customer.incomeType }));

const selectCustomerHumanReadable = createSelector(
  [selectAgeBracket, selectCustomer, selectCustomerAges],
  (ageBracket, customer, ages) => ({
    ageBracket,
    ages,
    incomeTier: getIncomeTier(customer).text,
    incomeType: toLower(constants.INCOME_TYPE_LABEL[customer.incomeType]),
    scale: lowerCase(constants.SCALE_LABEL[customer.scale]),
    state: constants.STATE_LABEL[customer.state],
  }),
);

export default selectCustomerHumanReadable;

export type CustomerHumanReadablePropTypes = {
  ageBracket: AgeBracket;
  ages: MemberYouthDiscountAgesType;
  incomeTier: string;
  incomeType: string;
  state: string;
};

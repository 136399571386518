import { createSelector } from 'reselect';
import { get, includes, isEmpty, pick } from 'lodash/fp';

import constants from '../../ahm-constants';
import getIsExtrasComparerPage from '../../utils/get-is-extras-comparer-page';
import selectCart from '../select-cart';
import selectExcessToggleValues from '../select-excess-toggle-values';
import selectLocation from '../select-location';
import selectNeedsProductType from '../select-needs-product-type';
import selectNeedsType from '../select-needs-type';

const { URLS } = constants;
const MINIMUM_EXCESS_VALUES_FOR_TOGGLE = 2;

export const restrictedLandingPages = pick(
  ['MEDIA_LANDING', 'NO_GAP_DENTAL', 'OFFER_FRIEND', 'PREGNANCY', 'TAX'],
  URLS.SALES,
);

// @ts-ignore - Automatic, Please fix when editing this file
const isExcessToggleApplicable = (cart, location, excessToggleValues, needsProductType?: any, needsType?: any) => {
  const isOnRestrictedLandingPage = includes(location, restrictedLandingPages);
  const hasOnlyExtrasInCart =
    get('baseProductType')(cart) === constants.PRODUCT_TYPE.EXTRAS && isEmpty(get('hospital.id')(cart));
  const isOnSingleExcessProduct = excessToggleValues.length < MINIMUM_EXCESS_VALUES_FOR_TOGGLE;

  return !(
    hasOnlyExtrasInCart ||
    isOnSingleExcessProduct ||
    isOnRestrictedLandingPage ||
    getIsExtrasComparerPage({ needsProductType, needsType })
  );
};

const selectIsExcessToggleApplicable = createSelector(
  [selectCart, selectLocation, selectExcessToggleValues, selectNeedsProductType, selectNeedsType],
  isExcessToggleApplicable,
);

export default selectIsExcessToggleApplicable;

export type IsExcessToggleApplicableType = boolean;

export { isExcessToggleApplicable };
